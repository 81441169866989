import Action from 'types/Action';
import { SelectOption } from 'types/common/Item';

export enum COMMON_MESSAGE {
    ERROR_MESSAGE = 'Có lỗi xảy ra trong quá trình thực hiện',
    PERMISSION_DENY = 'Bạn không có quyền thực hiện hành động này',
    EXPIRED_TOKEN = 'Phiên làm việc của bạn đã hết hạn, vui lòng đăng nhập lại',
    FIELD_REQUIRED = 'Trường này không được bỏ trống',
    FIELD_PASSWORD_LENGTH = 'Mật khẩu ít nhất 6 ký tự',
    FIELD_PASSWORD_MATCH = 'Mật khẩu không giống nhau',
    FIELD_EMAIL = 'Email không hợp lệ',
    FIELD_PHONE = 'Số điện thoại không hợp lệ',
    FIELD_NUMBER = 'Trường này phải là số',
    MIN_NUMBER = 'Giá trị bé nhất là ',
    MAX_NUMBER = 'Giá trị lớn nhất nhất là ',
    FIELD_DATE = 'Ngày tháng không hợp lệ',
    FIELD_REQUIRED_NAME = ' không được bỏ trống',
    FIELD_NUMBER_POSITIVE = 'Trường này phải lớn hơn 0',
}

export enum DEFAULT_VALUE {
    IMAGE = '/assets/images/dathang86/default.png',
    PRODUCT_IMAGE = '/assets/images/dathang86/no-product.png',
}

export const REACT_APP_API_URL = process.env.REACT_APP_API_URL ?? 'https://api.dathang86.vn';
export const REACT_APP_CHAT_URL = process.env.REACT_APP_CHAT_URL ?? 'https://chat.dathang86.vn';
export const REACT_APP_SOCKET_URL = process.env.REACT_APP_SOCKET_URL ?? 'wss://api.dathang86.vn';
export const REACT_APP_FE_URL = process.env.REACT_APP_FE_URL ?? 'https://dathang86.vn';

export const PAGINATION = {
    countItem: 0,
    totalPage: 1,
    currentPage: 1,
    limit: 10,
};

export const optionSelectDefault: SelectOption = { value: 0, label: '--' };

export const MenuCustomers: Action[] = [
    { id: 1, name: 'Thông tin tài khoản', url: '/customer/profile', icon: 'user-check' },
    { id: 2, name: 'Thông báo', url: '/customer/notification', icon: 'bell' },
    {
        id: 3,
        name: 'Chi tiết giao dịch',
        url: '/customer/transaction',
        icon: 'dollar-sign',
    },
    { id: 4, name: 'Đơn hàng', url: '/customer/order', icon: 'shopping-cart' },
    //{ id: 5, name: 'Đơn lưu trữ', url: '/customer/draffOrder', icon: 'shopping-bag' },
    { id: 6, name: 'Tạo đơn hàng', url: '/customer/addOrder', icon: 'plus-circle' },
    { id: 7, name: 'Báo khách', url: '/customer/product', icon: 'activity' },
    { id: 8, name: 'Khiếu nại', url: '/customer/complaint', icon: 'frown' },
    {
        id: 81,
        name: 'Tra cứu vận chuyển',
        url: '/customer/tracking',
        icon: 'truck',
    },
    {
        id: 82,
        name: 'Phiếu vận chuyển',
        url: '/customer/transport',
        icon: 'fast-forward',
    },
    { id: 9, name: 'Rút tiền', url: '/customer/withdrawal', icon: 'dollar-sign' },
    { id: 10, name: 'Mục lưu trữ SP', url: '/customer/wishList', icon: 'archive' },
    { id: 11, name: 'Chat', url: '/customer/chat', icon: 'message-circle' },
    { id: 12, name: 'Liên hệ', url: '/customer/11', icon: 'phone-call' },
];

export const MenuAffiliates: Action[] = [{ id: 1, name: 'Đơn hàng', url: '/affiliateOrder', icon: 'shopping-cart' }];
