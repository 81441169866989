import { COMMON_MESSAGE } from 'constants/common';
import { find, get, isEmpty } from 'lodash';
import { ToastOptions, toast } from 'react-toastify';
import { NotificationType } from 'types/Notification';
import { ItemParam, SelectOption } from 'types/common/Item';

export const showToast = (success: boolean, messages?: string[]) => {
    const options: ToastOptions<{}> = {
        position: toast.POSITION.TOP_RIGHT,
        toastId: Math.random(),
    };
    let MsgNode = null;
    if (messages && messages.length > 1) {
        MsgNode = (
            <div>
                {messages.map((message: string, index: number) => (
                    <p key={index}>{message}</p>
                ))}
            </div>
        );
    }
    if (success) {
        if (!isEmpty(messages)) {
            if (messages!.length === 1) {
                toast.success(messages![0], options);
            } else {
                toast.success(MsgNode, options);
            }
        }
    } else {
        if (!isEmpty(messages)) {
            if (messages!.length === 1) {
                toast.error(messages![0], options);
            } else {
                toast.error(MsgNode, options);
            }
        } else {
            toast.error(COMMON_MESSAGE.ERROR_MESSAGE, options);
        }
    }
};

export const genTableIndex = (index: number, limit: number, currentPage: number) =>
    index + limit * (currentPage - 1) + 1;

export const getFieldHtml = (fields: ItemParam[], itemId: number) => (
    <span className={getFieldInArrayObject(fields, itemId, 'className')}>{getFieldInArrayObject(fields, itemId)}</span>
);

export const getFieldInArrayObject = (
    listObj: {}[],
    id: number | string,
    fieldName: string = 'name',
    defaultValue: string = '',
    fieldCompare = 'id'
) => get(find(listObj, { [fieldCompare]: id }), fieldName, defaultValue);

export const toggleModalOpen = (show: boolean) => {
    if (show) {
        document.body.classList.add('modal-open');
    } else {
        document.body.classList.remove('modal-open');
    }
};

export const closeModal = (changeShow: Function) => {
    if (changeShow) {
        changeShow(false);
    }
};

export const selectItem = (listItems: ItemParam[], noNoneOption?: boolean) => {
    const selectOptions: JSX.Element[] = [];
    if (!noNoneOption) {
        selectOptions.push(
            <option key={0} value={0}>
                --
            </option>
        );
    }
    listItems.forEach((item) => {
        selectOptions.push(
            <option key={item.id} value={item.id}>
                {item.name}
            </option>
        );
    });
    return selectOptions;
};

export const convertToSelectOptions = (items: ItemParam[], noNoneOption?: boolean) => {
    const selectOptions: SelectOption[] = [];
    if (!noNoneOption) {
        selectOptions.push({
            value: 0,
            label: '',
        });
    }
    items.forEach((item) => {
        selectOptions.push({
            value: item.id,
            label: item.name,
        });
    });
    return selectOptions;
};

export const replaceImage = (image: string) => image.replace('https://dathang86.vn', 'https://chinavn.vn');

export const linkToNotification = (
    notificationTypeId: NotificationType,
    isCustomer: boolean,
    itemId: number,
    rootId: number
) => {
    let url = '';
    switch (notificationTypeId) {
        case NotificationType.ORDER:
            url = `${isCustomer ? '/customer' : ''}/order/edit/${itemId}`;
            break;
        case NotificationType.TRACKING:
        case NotificationType.SHOP_TRACKING:
            url = `${isCustomer ? '/customer' : '/shop'}/tracking`;
            break;
        case NotificationType.TRANSPORT:
            url = `${isCustomer ? '/customer' : ''}/transport?id=${itemId}`;
            break;
        case NotificationType.WITHDRAWAL:
            //TODO: popup
            url = `${isCustomer ? '/customer' : ''}/withdrawal?id=${itemId}`;
            break;
        case NotificationType.COMPLAINT:
            url = `${isCustomer ? '/customer' : ''}/order/edit/${rootId}?complaintId=${itemId}`;
            break;
        case NotificationType.SHOP_COMMENT:
        case NotificationType.SHOP:
            url = `${isCustomer ? '/customer' : ''}/order/edit/${rootId}?shopId=${itemId}`;
            break;
        case NotificationType.TRANSACTION:
            //TODO: popup
            if (isCustomer) url = `/customer/transaction?id=${itemId}`;
            else url = `/transaction/receipt?id=${itemId}`;
            break;
        case NotificationType.PRODUCT:
            url = `${isCustomer ? '/customer' : ''}/order/edit/${rootId}?productId=${itemId}`;
            break;
        default:
            break;
    }
    return url;
};
